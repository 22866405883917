@import "../stylesheets/vars.scss";

div.info-label {
  display: flex;
  color: $blue;
  align-content: center;
  align-items: center;

  label.checkbox {
    cursor: pointer;
  }

  span.tool-tip-section {
    opacity: .4;
    display: inline-block;
    padding: 0 5px;
    font-size: 10px;
    cursor: pointer;
    position: relative;

    &:hover {
      opacity: 1;
    }

    span.tool-tip-data-container {
      position: absolute;
      bottom: -5px;
    }

    .inner-tooltip {
      padding: 5px 15px;

      h3 {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: bold;
      }
    }
  }

  section.copy-to-clipboard {
    margin-left: auto;
    font-size: 18px;
    color: $blue;
    padding: 6px;
    cursor: pointer;

    &:hover {
      color: darken($blue, 10%);
    }

    svg.copied {
      animation: pulse .8s;
      animation-iteration-count: 1;
      border-radius: 50%;
      position: relative;
    }

    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 white;
      }
      55% {
        box-shadow: 0 0 6px 6px lighten($blue, 15%);
      }
      100% {
        box-shadow: 0 0 0 0 white;
      }
    }

  }

}

.__react_component_tooltip {
  opacity: 1 !important;
  border-radius: $br;
  box-shadow: 1px 2px 4px 1px $hover;
  background-color: $lightest-grey;
  color: $dark-grey;
}

.__react_component_tooltip.place-top:after {
  border-top-color: $lighter-grey !important;
}
