@import "stylesheets/vars.scss";

div.checkbox {
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: inline-block;

    &:focus {
      outline: none;
    }
  }

  div.info-label {
    display: inline-block;
    margin-left: 10px;
  }

  .label-as-checkbox {
    span {
      border: 2px solid $blue;
      border-radius: 4px;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
    &.disabled {
      span {
        border: 2px solid $medium-grey;
        cursor: not-allowed;
      }
    }
  }

  input[type="checkbox"] + label span {
    background-color: white;

    svg {
      color: transparent;
    }
  }

  input[type="checkbox"]:checked + label span {
    background: $blue;

    svg {
      background-color: $blue;
      color: white;
      padding: 0 2px;
    }
  }

  input[type="checkbox"][disabled]:checked + label span {
    background: $medium-grey;

    svg {
      background-color: $medium-grey;
      color: white;
      padding: 0 2px;
    }
  }

}
