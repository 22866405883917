@import "vars.scss";

.button {
  display: inline-block;
  padding: 10px 15px;
  background-color: white;
  color: $blue;
  border: 1px solid $lighter-grey;
  border-radius: $br;
  font-size: 14px;
  text-align: center;
  cursor: pointer;

  text-decoration: none;
  &:hover,
  &:visited {
    text-decoration: none;
  }
  &:hover:not(:disabled) {
    color: darken($blue, 15%);
    background-color: darken(white, 3%);
  }

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:disabled {
    color: $lighter-grey;
    cursor: not-allowed;
  }

  i {
    margin-left: 10px;
    color: $medium-grey;
  }

  &.blue,
  &.red,
  &.green {
    color: white;
    border: none;

    i {
      color: white;
      margin: 0;
      font-size: 14px;
    }
  }

  &.blue {
    background-color: $blue;
    &.error {
      background-color: $red;
    }
    &:hover:not(:disabled) {
      color: darken(white, 3%);
      background-color: darken($blue, 5%);
    }
  }

  &.red {
    background-color: $red;
  }
  &.green {
    background-color: $green;
  }
}

.button-group > * {
  margin-bottom: 10px;
}
