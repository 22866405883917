// Colors
$blue: #68b0cc;
$lighter-blue: #a8d9e6;
$light-blue: #f0fafe;
$cancel: #bdccd5;

$darker-blue: #4b4b4b;
$red: #b62222;
$light-green: #f4fde8;
$primary-green: #419175;
$green: #158e4d;
$dark-green: #276b1e;
$orange: #fd8a25;
$background: #f4f7fa;
$light-grey: #f5f5f5;
$lighter-grey: #d9d9d8;
$lightest-grey: #fbfbfb;
$hover: #c1c1c1;
$medium-grey: #a6b6be;
$dark-grey: #565656;

// borders
$border: rgba(black, 0.1);
$border-light: rgba(white, 0.2);

// Sizes
$br: 5px;

// Screen
$medium: 1280px;
$medium-padding: 25px;
$medium-margin: 30px;

//Tablet
$tablet-max: 980px;
