.app-container {
  padding: 10px;
  min-height: 100vh;

  background-color: $background;

  .header-container {
    margin: 10px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 1220px;
    margin: auto;
    padding: 15px 20px;

    background: white;
    border-top: 2px solid $blue;
    color: $blue;

    h2 {
      font-size: 22px;
      font-weight: bold;
      cursor: pointer;
    }

    svg.balancer {
      cursor: pointer;

      &.balancing {
        #rotate-container-left {
          transform-origin: 38% 66%;
          animation: goup 3s ease infinite;
        }

        #rotate-container-right {
          transform-origin: 46% 34%;
          animation: goup 3s ease infinite;
        }

      }

      @keyframes goup {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(30deg);
        }
        100% {
          transform: rotate(00deg);
        }
      }
    }
  }

  .pages-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .screen-left,
  .right-screen {
    margin: 10px;
    overflow: auto;
    min-width: 300px;
    flex: 1;
    height: fit-content;
  }

  .screen-left {
    max-width: 500px;
  }

  .right-screen {
    max-width: 700px;

    input,
    #json-pretty {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .tabs {
    display: flex;

    .tab {
      flex: 1;
      padding: 20px;
      border-top-width: 2px;
      border-top-style: solid;
      cursor: pointer;

      &:not(.active) {
        background: $light-grey;
        border-color: $light-grey;
        border-right: 3px solid white;

        h2 {
          color: $dark-grey;
        }
      }

      &.single-tab {
        padding-bottom: 10px;
      }

      &.active {
        background: white;
        border-color: $blue;

        h2 {
          color: $blue;
          font-weight: bold;
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .block {
    background-color: white;
    margin-bottom: 20px;
    padding: 20px;

    &.retrieve-content,
    &.no-data {
      padding-bottom: 10px;
    }
  }
}
