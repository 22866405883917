@import "./vars.scss";

body {
  font-family: "Roboto", Sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $dark-grey;
  -webkit-font-smoothing: antialiased;

  &:after {
    position: fixed;
    width: 109px;
    height: 28px;
    top: 15px;
    right: -25px;
    text-align: center;
    font-size: 16px;
    font-family: sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    line-height: 30px;
    transform: rotate(45deg);
  }

  &.ReactModal__Body--open {
    overflow: hidden;
  }
}

a {
  cursor: pointer;

  &:visited {
    color: $blue;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: grey;
}

::-moz-placeholder {
  color: grey;
  opacity: 1;
}
