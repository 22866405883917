@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url(../fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(../fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"),
    url(../fonts/Roboto-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"),
    url(../fonts/Roboto-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Mono"), local("RobotoMono-Regular"),
    url(../fonts/RobotoMono-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Mono Light"), local("RobotoMono-Light"),
    url(../fonts/RobotoMono-Light.ttf) format("truetype");
}
