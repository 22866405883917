@import "./vars.scss";

$inputPadding: 0 10px;

$borderRadius: 4px;
$borderWidth: 1px;
$borderColor: $border;
$hoverBorderColor: $blue;

$fontFamily: "Roboto";
$fontSize: 14px;
$fontStyle: normal;
$fontWeight: 400;
$color: $dark-grey;

$transition: all 100ms;

// attrs for the ReactSelect component
:export {
  inputPadding: $inputPadding;
  borderColor: $borderColor;
  borderRadius: $borderRadius;
  borderWidth: $borderWidth;
  hoverBorderColor: $hoverBorderColor;
  fontFamily: $fontFamily;
  fontSize: $fontSize;
  fontStyle: $fontStyle;
  fontWeight: $fontWeight;
  color: $color;
  transition: $transition;
  blue: $blue;
  red: $red;
  grey: $medium-grey;
}

fieldset,
.fieldset {
  margin-bottom: 20px;
  border: none;

  &:last-child {
    margin-bottom: 0px;
  }
}

.field-block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  fieldset {
    flex: 1;
    margin-bottom: 0px;

    &:nth-child(2) {
      margin-left: 20px;
    }
  }
}

label {
  display: block;
  margin-left: 1px;
  margin-bottom: 5px;

  font-size: $fontSize;
  color: black;
}

input {
  width: 100%;
  min-height: 40px;
  padding: $inputPadding;

  border-style: solid;
  border-radius: $borderRadius;
  border-color: $borderColor;
  border-width: $borderWidth;

  font-size: $fontSize;
  font-family: $fontFamily;
  font-style: $fontStyle;
  font-weight: $fontWeight;
  color: $color;

  transition: $transition;

  &:hover {
    border-color: $hoverBorderColor;
  }
  &[disabled] {
    background-color: $lightest-grey;
    cursor: not-allowed;
  }
}
